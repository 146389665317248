// Updated useTokensHook
import { useEffect, useState } from 'react'
import apiConfig from '@/config/relysiaApi'
import { useSelector } from 'react-redux'
import walletSelector from '@/redux/selectors/wallet'
import authSelector from '@/redux/selectors/auth'

export default function useTokens() {
  const { userData } = useSelector(authSelector)
  const [tokens, setTokens] = useState([])
  const [nextPageToken, setNextPageToken] = useState(null)
  const [loading, setLoading] = useState(true)
  const { currentWalletId } = useSelector(walletSelector)
  const [pageTokens, setPageTokens] = useState([])

  let canLoadMore = Boolean(nextPageToken)
  async function fetchTokens() {
    setLoading(true)
    let headers = {}
    let url = `/v2/balance`
    if (currentWalletId) headers.walletID = currentWalletId
    if (pageTokens[pageTokens.length - 1]) {
      url = `/v2/balance?nextPageToken=${pageTokens[pageTokens.length - 1]}`;
    }

    const { data: { coins, meta: { nextPageToken: nextToken } } = {} } = (
      await apiConfig.get(url, { headers })
    )?.data

    setNextPageToken(nextToken)
    setPageTokens(e => [...e, nextToken])
    setLoading(false)
    return coins
  }

  async function loadMore() {
    // loadMore fetches more tokens & nfts if there is a next page token
    // and merges the new chunk of tokens with the existing chunk
    const moreTokens = await fetchTokens()
    let stasTokens = moreTokens.filter((coin) => coin.protocol !== 'BSV')
    setTokens((prevTokens) => [...prevTokens, ...stasTokens])
  }

  useEffect(() => {
    if (!userData) return

    // runs on first load of hook and whenever user or
    // currentwalletId changes
    fetchTokens().then((res) => {
      let stasTokens = res.filter((coin) => coin.protocol !== 'BSV')
      return setTokens(stasTokens)
    })
  }, [userData, currentWalletId])

  return { tokens, canLoadMore, loadMore, loading, pageTokens, setPageTokens }
}
