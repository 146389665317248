import { QrReader } from 'react-qr-reader'
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
const VIDEO_ELEMENT_ID = 'qrcode-video'
import QRCode from 'qrcode.react'

export default function QrcodeReader({ open, setOpen, onText, walletAddress }) {
  const [qrData, setQrData] = useState('')

  const handleError = (error) => {
    console.error('QR Code Scanner Error: ', error)
  }

  const handleScan = (result) => {
    if (result) {
      console.log('QR Code Scanned:', result)
      setQrData(result)
      onText(result)
    }
  }

  function closeModal() {
    setOpen(false)
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          ref={Fragment}
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Scan QR Code
                </Dialog.Title>
                <QrReader
                  onResult={(result) => {
                    if (result?.text) onText(result.text)
                  }}
                  videoId={VIDEO_ELEMENT_ID}
                  style={{ width: '100%' }}
                  constraints={{ facingMode: 'environment' }}
                />
                {open && (
                  <div>
                    <button onClick={closeModal}>Close Scanner</button>
                    <QrReader
                      delay={300} // Delay between scans in milliseconds
                      onError={handleError}
                      onResult={handleScan}
                      style={{ width: '100%' }}
                    />
                  </div>
                )}

                {/* <QRCode open={open} setOpen={setOpen} value={open} style={{ width: '100%', height: '100%' }}
                  constraints={{ facingMode: 'environment' }} /> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
      {qrData && (
        <div className="fixed inset-0 flex items-center justify-center z-[9998]">
          <div>
            <p>Scanned Data:</p>
            <p>{qrData}</p>
            {/* Display the QR code based on the scanned data */}
            <img
              src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${qrData}`}
              alt="QR Code"
            />
          </div>
        </div>
      )}
    </Transition>
  )
}
